import {
  AddressFormInputs,
  GooglePlacesResponse,
  MagentoRegion,
} from '../types/CartTypes'

// Google API returns full name of states in NZ instead of state code, so we need to set the state code ourselves
const getStateCode = (regions, stateName) => {
  const state = regions.find(region => region.name === stateName)
  return state.code
}

export const getMagentoRegionFromGooglePlaceRegion = (
  regions: MagentoRegion[],
  statecode: string,
  country: string
) => {
  if (country === 'New Zealand') {
    statecode = getStateCode(regions, statecode)
  }
  const region = regions?.find(({ code }) => code === statecode)
  return `${region?.id},${region?.code},${region?.name}`
}
export const getFormattedDataFromPlaces = (
  response: GooglePlacesResponse
): AddressFormInputs => {
  let address1, postcode, locality, state, country
  for (const component of response.address_components) {
    const componentType = component.types[0]
    switch (componentType) {
      case 'street_number': {
        address1 = `${component.long_name} `
        break
      }
      case 'route': {
        address1 += component.short_name
        break
      }
      case 'postal_code': {
        postcode = `${component.long_name}`
        break
      }
      case 'locality':
        locality = component.long_name
        break
      case 'administrative_area_level_1': {
        state = component.short_name
        break
      }
      case 'country':
        country = component.long_name
        break
    }
  }
  return {
    address1,
    state,
    country,
    locality,
    postcode,
  }
}
